import "./breadcrumps.scss";

import React from "react";
import { Link } from "gatsby";

const Breadcrumps = ({ data }) => {
  return (
    <div className="breadcrumps">
      <div className="container">
        <Link href="/">Strona główna</Link>
        {Array.isArray(data) ? (
          data.map((item, index) =>
            index !== data.length - 1 ? (
              <Link to={item.href}>{item.name}</Link>
            ) : (
              <span>{item.name}</span>
            )
          )
        ) : (
          <span>{data}</span>
        )}
      </div>
    </div>
  );
};

export default Breadcrumps;
