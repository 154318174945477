/* eslint-disable jsx-a11y/heading-has-content */

import "./reviews-slider.scss";
import React from "react";
// import Slider from "react-slick";

// const sliderSettings = {
//   dots: false,
//   arrows: true,
//   infinite: true,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   adaptiveHeight: true,
//   fade: true,
//   responsive: [
//     {
//       breakpoint: 575,
//       settings: {
//         fade: false,
//       },
//     },
//   ],
// };

const ReviewsSlider = ({ reviewsData }) => {
  // const reviews = reviewsData.comments;

  return (
    <div className="reviews-slider">
      {/* <Slider {...sliderSettings}>
        {reviews?.map((item, index) => {
          const { commentAuthor, commentSource, commentContent } = item;
          return (
            <div key={index} className="reviews-slider__single-slide">
              <div className="reviews-slider__slide-header">
                <div className="reviews-slider__author-name">
                  {commentAuthor}
                </div>
                <div className="reviews-slider__source">
                  Opinia z{" "}
                  <img
                    src={require(`assets/images/${commentSource}.svg`).default}
                    alt=""
                  />
                </div>
              </div>
              <div
                className="reviews-slider__text"
                dangerouslySetInnerHTML={{ __html: commentContent }}
              ></div>
            </div>
          );
        })}
      </Slider> */}
      <div className="reviews-slider__ratings-container">
        <div className="reviews-slider__single-rating">
          <div className="reviews-slider__circle-wrapper">
            <div className="reviews-slider__circle reviews-slider__circle--blue">
              <strong>
                5<i>/5</i>
              </strong>
              <span>21 opinii</span>
            </div>
          </div>
          <div className="reviews-slider__logo-wrapper">
            <img
              src={require("assets/images/google.svg").default}
              alt=""
              className="img-fluid reviews-slider__logo"
            />
          </div>
          <h4>Warszawa</h4>
          <a
            href="https://www.google.com/search?q=spok%C3%B3j+w+g%C5%82owie+poradnia+warszawa&sca_esv=569101513&ei=HEcVZdbMG9Pc7_UP6M69wAE&ved=0ahUKEwjWysfi_syBAxVT7rsIHWhnDxgQ4dUDCBA&uact=5&oq=spok%C3%B3j+w+g%C5%82owie+poradnia+warszawa#lrd=0x471ec9d33a1f0bdf:0xc704f8e5b4fd9a66,1,,,,"
            target="_blank"
            rel="noreferrer nofollow"
            className="button button--green"
          >
            Zobacz opinie
          </a>
        </div>
        <div className="reviews-slider__single-rating">
          <div className="reviews-slider__circle-wrapper">
            <div className="reviews-slider__circle reviews-slider__circle--blue">
              <strong>
                {reviewsData.commentsRatingGoogle}
                <i>/5</i>
              </strong>
              <span>{reviewsData.commentsNumberGoogle}</span>
            </div>
          </div>
          <div className="reviews-slider__logo-wrapper">
            <img
              src={require("assets/images/google.svg").default}
              alt=""
              className="img-fluid reviews-slider__logo"
            />
          </div>
          <h4>Wołomin</h4>
          <a
            href="https://www.google.com/search?q=spokojwglowie&ei=0A8iYpC3AuWkrgTPzJ3QCg&ved=0ahUKEwiQ36Duw6z2AhVlkosKHU9mB6oQ4dUDCA4&uact=5&oq=spokojwglowie&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCAAQgARKBAhBGABKBAhGGABQAFgAYIkDaABwAXgAgAFfiAFfkgEBMZgBAKABAcABAQ&sclient=gws-wiz-serp#lrd=0x471ec5505346b779:0x67dbde625b23de1a,1,,"
            target="_blank"
            rel="noreferrer nofollow"
            className="button button--green"
          >
            Zobacz opinie
          </a>
        </div>
        <div className="reviews-slider__single-rating">
          <div className="reviews-slider__circle-wrapper">
            <div className="reviews-slider__circle reviews-slider__circle--green">
              <strong>
                {reviewsData.commentsRatingZl}
                <i>/5</i>
              </strong>
              <span>{reviewsData.commentsNumberZl}</span>
            </div>
          </div>
          <div className="reviews-slider__logo-wrapper">
            <img
              src={require("assets/images/znanylekarz.svg").default}
              alt=""
              className="img-fluid reviews-slider__logo"
            />
          </div>
          <h4></h4>
          <a
            href="https://www.znanylekarz.pl/monika-chrapinska-krupa/psycholog-psycholog-dzieciecy-psychoterapeuta/wolomin"
            target="_blank"
            rel="noreferrer nofollow"
            className="button button--green"
          >
            Zobacz opinie
          </a>
        </div>
        <div className="reviews-slider__single-rating">
          <div className="reviews-slider__circle-wrapper">
            <div className="reviews-slider__circle reviews-slider__circle--orange">
              <strong>
                {reviewsData.commentsRatingBooksy}
                <i>/5</i>
              </strong>
              <span>{reviewsData.commentsNumberBooksy}</span>
            </div>
          </div>
          <div className="reviews-slider__logo-wrapper">
            <img
              src={require("assets/images/booksy.svg").default}
              alt=""
              className="img-fluid reviews-slider__logo"
            />
          </div>
          <h4></h4>
          <a
            href="https://booksy.com/pl-pl/102864_spokoj-w-glowie-poradnia-psychologiczna-wolomin_zdrowie_4277_wolomin"
            target="_blank"
            rel="noreferrer nofollow"
            className="button button--green"
          >
            Zobacz opinie
          </a>
        </div>
      </div>
    </div>
  );
};

export default ReviewsSlider;
