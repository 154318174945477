import React from "react";
import { useStaticQuery, graphql } from "gatsby"

import Divider from "components/Divider";
import ReviewsSlider from "components/ReviewsSlider";

const ReviewSection = () => {

    const pageData = useStaticQuery(
        graphql`
        query reviewsSlide {
            allWpPage(filter: { title: { eq: "Home" } }) {
                nodes {
                    pageHome {
                        comments {
                        commentAuthor
                        commentContent
                        commentSource
                        }
                        commentsTitle
                        commentsNumberBooksy
                        commentsNumberGoogle
                        commentsNumberZl
                        commentsRatingBooksy
                        commentsRatingGoogle
                        commentsRatingZl
                    }
                }
            }
        } 
        `
    );

    const reviewsData = pageData.allWpPage?.nodes[0]?.pageHome;

    return (
        <section className="home-reviews">
            <div className="container">
            <h2>{reviewsData.commentsTitle}</h2>
            <Divider marginBottom={45} marginTop={15} />
            <ReviewsSlider reviewsData={reviewsData} />
            </div>
      </section>
    )
}

export default ReviewSection;
