import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

const Booking = () => {
  return (
    <section className="about-booking">
      <div className="container">
        <h2>Jak się do nas zapisać?</h2>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="about-booking__item">
              <span>Krok 1</span>
              <h3>Skorzystaj z formularza rezerwacji wizyt.</h3>
              <div className="about-booking__item-image">
                <img
                  className="img-fluid"
                  src={
                    require("assets/images/about-booking-icon-1.svg").default
                  }
                  alt=""
                />
              </div>
              <h4>Wybierz dogodną lokalizację dla siebie.</h4>
              <ul>
                <li>Warszawa</li>
                <li>Wołomin</li>
                <li>lub wizyta online z Twojego domu</li>
              </ul>
              <Link
                to="/rezerwacja-wizyt/"
                className="button button--green button--small"
              >
                Umów wizytę
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="about-booking__item">
              <span>Krok 2</span>
              <h3>Zadzwoń do nas.</h3>
              <div className="about-booking__item-image">
                <img
                  className="img-fluid"
                  src={
                    require("assets/images/about-booking-icon-2.svg").default
                  }
                  alt=""
                />
              </div>
              <h4>Wybierz numer placówki, do której chcesz się zapisać.</h4>
              <p>Wołomin</p>
              <div className="about-booking__item-row">
                <a
                  href="tel:+48 501 775 717"
                  className="button button--red button--small"
                >
                  501 775 717
                </a>
                <a
                  href="tel:+48 721 642 466"
                  className="button button--red button--small"
                >
                  721 642 466
                </a>
              </div>
              <p>Warszawa</p>
              <a
                href="tel:+48 725 396 700"
                className="button button--red button--small"
              >
                725 396 700
              </a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="about-booking__item">
              <span>Krok 3</span>
              <h3>Masz pytania?</h3>
              <div className="about-booking__item-image">
                <img
                  className="img-fluid"
                  src={
                    require("assets/images/about-booking-icon-3.svg").default
                  }
                  alt=""
                />
              </div>
              <h4>
                Skorzystaj z formularza kontaktowego i wyślij nam swoje
                zapytanie.
              </h4>
              <ul></ul>
              <Link
                to="/kontakt/"
                className="button button--green button--small"
              >
                Kontakt
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Booking;
