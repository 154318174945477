import "./divider.scss";

import React from "react";

const Divider = ({ marginTop, marginBottom }) => {
  return (
    <div
      className="divider"
      style={{ marginTop: marginTop, marginBottom: marginBottom }}
    ></div>
  );
};

export default Divider;
