import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Breadcrumps from "components/Breadcrumps";
import ReviewSection from "components/ReviewSection";

import { Content, Approach, Locations, Booking } from "page_components/about";

const About = ({ data }) => {
  const title = "O nas";
  const pageData = data?.allWpPage?.nodes[0]?.acfAbout;

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumps data={title} />
      <SubpageHeader title={title} />
      <Content pageData={pageData} />
      <ReviewSection />
      <Approach pageData={pageData} />
      <Locations />
      <Booking />
    </Layout>
  );
};

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDoxNjQyMQ==" } }) {
      nodes {
        acfAbout {
          mainHeader
          mainContent
          mainImage {
            localFile {
              publicURL
            }
          }
          bottomHeader
          bottomContent
        }
      }
    }
  }
`;

export default About;
