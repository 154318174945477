import "./styles.scss";

import React from "react";

const Content = ({ pageData }) => {
  return (
    <section className="about-content">
      <div className="container">
        <h2>{pageData?.mainHeader}</h2>
        <div className="row">
          <div className="col-md-7">
            <div dangerouslySetInnerHTML={{ __html: pageData?.mainContent }} />
          </div>
          <div className="col-lg-4 offset-lg-1 col-md-5">
            <img
              className="img-fluid"
              src={pageData?.mainImage?.localFile?.publicURL}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Content;
