import "./styles.scss";

import React from "react";

const Approach = ({ pageData }) => {
  return (
    <section className="about-approach">
      <div className="container">
        <h2>{pageData?.bottomHeader}</h2>
        <div dangerouslySetInnerHTML={{ __html: pageData?.bottomContent }} />
      </div>
    </section>
  );
};

export default Approach;
