import "./subpage-header.scss";

import React from "react";

const SubpageHeader = ({ title, smallMargin }) => {
  return (
    <div
      className={`subpage-header ${smallMargin && "subpage-header--margin"}`}
    >
      <h1 className="subpage-header__title">{title}</h1>
    </div>
  );
};

export default SubpageHeader;
