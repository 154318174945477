import "./styles.scss";

import React from "react";

const Locations = () => {
  return (
    <section className="about-locations">
      <div className="container">
        <h2>Jesteśmy w dwóch lokalizacjach</h2>
        <div className="row">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("assets/images/about-location-image-1.jpg").default}
              alt=""
            />
            <h3>Poradnia w Wołominie</h3>
            <p>ul. Miła 3, 05-200 Wołomin, 3 piętro</p>
          </div>

          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("assets/images/about-location-image-2.jpg").default}
              alt=""
            />
            <h3>Poradnia Warszawa Białołęka</h3>
            <p>ul. Mochtyńska 65, 03-289 Warszawa</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Locations;
